import { zodResolver } from "@hookform/resolvers/zod";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import React from "react";
import { useForm } from "react-hook-form";

import useQueryClient from "../../../api/query";
import { stall } from "../../../utils/utils";
import { MASK } from "../../redact";
import {
  CREDENTIAL_TYPE,
  isNewPayer,
  isSupportPayer,
  typeFromCredential,
} from "../common";
import {
  UpdatePayerCredentialsFormFields,
  updatePayerCredentialsFormSchemaBase,
} from "./PayerCredentialsForm.zod";
import { UpdatePayerCredentialFormFields } from "./UpdatePayerCredentialFormFields";

type UpdateUserCredentialType = {
  updateUserCredential: (credentials: Partial<UserCredentialsMessage>) => void;
};

type GetUserCredentialsType = {
  getUserCredentials: () => void;
};

interface UpdatePayerCredentialsProps {
  credentials: Partial<
    UserCredentialsMessage & UpdateUserCredentialType & GetUserCredentialsType
  >;
  afterFormSubmit?: () => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  supportedPayersMap: Record<string, CredentialsSupportedPayersMessage>;
  onClose: () => void;
}

export function UpdatePayerCredentials({
  credentials,
  afterFormSubmit,
  updateSnackBar,
  supportedPayersMap,
  onClose,
}: UpdatePayerCredentialsProps) {
  const {
    control,
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
  } = useForm<UpdatePayerCredentialsFormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    delayError: 1000,
    resolver: zodResolver(updatePayerCredentialsFormSchemaBase),
  });
  const queryClient = useQueryClient();
  const { wieldyId: credentialId } = credentials;

  const onUpdate = async ({
    username,
    password,
    website,
    notes,
  }: Omit<UpdatePayerCredentialsFormFields, "confirmPassword">) => {
    try {
      const body = omitBy(
        {
          username,
          password,
          website,
          notes,
        },
        (value) => isNil(value) || value === MASK,
      );

      if (credentialId && !isEmpty(body)) {
        const credentialType = typeFromCredential(credentials);
        if (credentialType === CREDENTIAL_TYPE.PAYER) {
          if (isSupportPayer(credentials)) {
            await Promise.all([
              await queryClient.updateCreateCredentials({
                credentialId,
                ...body,
              }),
              await stall(),
            ]);
          } else if (isNewPayer(credentials)) {
            await Promise.all([
              await queryClient.updateOtherCredentials({
                credentialId,
                ...body,
              }),
              await stall(),
            ]);
          }
        }
        const { updateUserCredential, getUserCredentials } = credentials;

        if (
          typeof updateUserCredential === "function" &&
          typeof getUserCredentials === "function"
        ) {
          updateUserCredential(body);
          getUserCredentials();
        }

        if (typeof afterFormSubmit === "function") {
          afterFormSubmit();
        }
        updateSnackBar({
          severity: "success",
          message: "Successfully updated credentials.",
        });
      }
    } catch (error) {
      updateSnackBar({
        severity: "error",
        message: "Failed to update credentials. Try again.",
      });
      // eslint-disable-next-line no-console
      console.error(`Failed to update credentials: ${error}`);
    }
  };

  const hasErrors = Object.keys(errors).length > 0;
  const isDisabled = isSubmitting || hasErrors || !isValid;

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(async ({ username, website, notes, password }) => {
        await onUpdate({
          username,
          website,
          notes,
          password,
        });
      })}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <UpdatePayerCredentialFormFields
        control={control}
        currentCredentials={credentials}
        supportedPayersMap={supportedPayersMap}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
        }}
      >
        <Button
          fullWidth
          sx={{ borderRadius: "8px" }}
          onClick={onClose}
          color="primary"
          data-testid="cancel"
        >
          Cancel
        </Button>
        <Button
          fullWidth
          sx={{ borderRadius: "8px" }}
          type="submit"
          autoFocus
          color="primary"
          variant="contained"
          disabled={isDisabled}
          data-testid="updateCredentials"
        >
          {isSubmitting ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <CircularProgress size={20} />
              Submitting...
            </Box>
          ) : (
            "Save"
          )}
        </Button>
      </Box>
    </Box>
  );
}

UpdatePayerCredentials.defaultProps = {
  afterFormSubmit: undefined,
};
