import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import React from "react";

import { UpdatePayerCredentials } from "../Form/UpdatePayerCredentials";
import { LineBreak } from "../ui/LineBreak";
import UserSignalIcon from "../ui/UserSignalIcon.svg";

interface CredentialsSettingsDialogProps {
  credentials: Partial<UserCredentialsMessage>;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  supportedPayersMap: Record<string, CredentialsSupportedPayersMessage>;
  open: boolean;
  onClose: () => void;
}

export function CredentialsSettingsDialog({
  credentials,
  updateSnackBar,
  supportedPayersMap,
  open,
  onClose,
}: CredentialsSettingsDialogProps) {
  const { updatedAt } = credentials;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="credential-settings-dialog-title"
      aria-describedby="credential-settings-dialog-description"
      sx={{
        "& .MuiPaper-root": {
          maxWidth: "735px",
          width: "100%",
          padding: "24px 36px",
          margin: "auto",
          borderRadius: "12px",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F2F4F7",
              width: "48px",
              height: "48px",
              borderRadius: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "8px solid #F9FAFB",
            }}
          >
            <img
              src={UserSignalIcon}
              alt="Credentials Icon"
              width="24"
              height="24"
            />
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: "#475467",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle
          id="credential-settings-dialog-title"
          sx={{
            fontWeight: 600,
            color: "#303030",
            fontSize: "18px",
            paddingX: 0,
          }}
        >
          Edit Credentials
        </DialogTitle>
        {updatedAt && (
          <Typography
            sx={{
              color: "#475467",
              fontWeight: 400,
              fontSize: "16px",
              paddingX: 0,
            }}
          >
            Registration Date: {dayjs(updatedAt).format("MM/DD/YYYY")}
          </Typography>
        )}
      </Box>

      <LineBreak />

      <DialogContent sx={{ padding: 0 }}>
        <UpdatePayerCredentials
          afterFormSubmit={onClose}
          credentials={credentials}
          updateSnackBar={updateSnackBar}
          supportedPayersMap={supportedPayersMap}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  );
}
